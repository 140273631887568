body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.imgCon{width: 120px;height: 120px;line-height: 100px;overflow: hidden;cursor:pointer;}
.imgCon img{width:1000px; height:120px; border: 1px solid black;}
/* .menu-sub {
  display: none; 
}

.menu-sub.open {
  display: block;
}

.menu-toggle {
  cursor: pointer;
} */

.circular_image {
  width: 50%;
  height: 15%;
  border-radius:60%;
  overflow: hidden;
  background-color: gainsboro;
  /* commented for demo
  float: left;
  margin-left: 125px;
  margin-top: 20px;
  */
  
  /*for demo*/
  display:inline-block;
  vertical-align:middle;
}
.circular_image img{
  width:100%;
}